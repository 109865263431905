import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { Headline } from '@beelineloans/cx-library/src/components/typography';
import { StartApplication, RatesHelp, CalculatorLegals } from '@beelineloans/cx-library/src/components/layout/panels';
import ContactForm from '@beelineloans/cx-library/src/components/forms/ContactForm';
import Rates from '@beelineloans/cx-library/src/components/forms/calculator/Rates';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';

const HeadlineContainer = styled(Container)``;

const RatesPage = ({ isPurchase }) => {
  const shiftFocus = useRef();
  const [results, setResults] = useState(null);
  const [firstSearch, setFirstSearch] = useState(process.env.GATSBY_CALCULATOR_SERVICE === '2');

  const headlineBlock = isPurchase ? (
    <>
      Our rates are low because we use
      <br />
      lots of automation — not lots of people.
    </>
  ) : (
    <>
      Our refi rates are low because we use
      <br />
      lots of automation — not lots of people.
    </>
  );

  const handleSearchStart = (first) => {
    setFirstSearch(first);
    setResults(null);
  };

  const handleSearchComplete = (response) => {
    setResults(response);
  };

  const onLoadingStart = () => {
    if (!firstSearch) {
      setTimeout(() => {
        shiftFocus.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 200);
    }
  };

  return (
    <NavOnlyTemplate
      // headline={headlineBlock}
      // subHeadline="Get your customized rate, then go ahead and compare it."
      headerProps={{
        options: {
          videoType: isPurchase ? 'purchase' : 'refi',
          voomly: true
        }
      }}
    >
      <SEO
        isRefi={!isPurchase}
        path={isPurchase ? CONST.LINKS.MAIN.CALCULATORS.DEFAULT : CONST.LINKS.MAIN.CALCULATORS.REFINANCE}
        title={isPurchase ? 'Check out our low rates' : 'Check out our low refinance rates'}
        description={isPurchase ? 'Our rates are low because there are fewer steps and fewer people.' : 'Our refi rates are low because there are fewer steps and fewer people.'}
        antiFlicker
      />
      <HeadlineContainer>
        <Headline>{headlineBlock}</Headline>
      </HeadlineContainer>
      <div ref={shiftFocus}>
        <Rates onSearchComplete={handleSearchComplete} onSearchStart={handleSearchStart} isPurchase={isPurchase} onLoadingStart={onLoadingStart} />
      </div>
      {results !== null && results.status === 'NOT_AVAILABLE' && (
        <>
          <ContactForm contactType={CONST.CONTACT_TYPES.PROSPECT} title="Who should we contact?" state={results.location.state} />
        </>
      )}
      <RatesHelp />
      <StartApplication isPurchase={isPurchase} />
      <CalculatorLegals />
    </NavOnlyTemplate>
  );
};

RatesPage.propTypes = {
  isPurchase: PropTypes.bool
};

RatesPage.defaultProps = {
  isPurchase: true
};

export default RatesPage;
